import { modalSizes } from "@/config/constants";
import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesInvoices: RouteMetaConfig[] = [
  {
    path: "/invoices",
    name: routeNames.invoiceOverview,
    component: () => import("./InvoicesListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "invoices.view",
      listFilterName: "invoices",
    },
    children: [
      buildModalRoute(
        {
          path: "create",
          name: routeNames.invoiceCreate,
          meta: {
            requiresAuth: true,
            permission: "invoices.create",
          },
          component: () => import("./InvoiceCreateFormView.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          path: "accounting-export/bmd",
          name: routeNames.accountingExportBmd,
          meta: {
            requiresAuth: true,
            permission: "accounting_exports.create",
          },
          component: () =>
            import(
              "@/modules/accounting-export/views/AccountingExportBMDView.vue"
            ),
        },
        { fullscreen: false, width: modalSizes.unset }
      ),
      buildModalRoute(
        {
          path: "accounting-export/xero",
          name: routeNames.accountingExportXero,
          meta: {
            requiresAuth: true,
            permission: "accounting_exports.create",
          },
          component: () =>
            import(
              "@/modules/accounting-export/views/AccountingExportXeroView.vue"
            ),
        },
        { fullscreen: false, width: modalSizes.unset }
      ),
    ],
  },
  {
    path: "/invoices/:id(\\d+)",
    name: routeNames.invoiceDetails,
    component: () => import("./InvoiceDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "invoices.view",
    },
    children: [
      buildModalRoute(
        {
          path: "issue-credit-note",
          name: routeNames.invoiceIssueCreditNote,
          meta: {
            requiresAuth: true,
            permission: "credit_notes.create",
          },
          component: () => import("./InvoiceIssueCreditNoteView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
];

export const invoiceRoutesFactory = {
  invoiceList() {
    return {
      name: routeNames.invoiceOverview,
    };
  },
  invoiceDetails(id: number | string) {
    return {
      name: routeNames.invoiceDetails,
      params: {
        id,
      },
    };
  },
  invoiceIssueCreditNote(invoiceId: number) {
    return {
      name: routeNames.invoiceIssueCreditNote,
      params: {
        id: invoiceId,
      },
    };
  },
};
